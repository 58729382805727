<template lang="pug">

div
  v-btn(fixed dark fab bottom right color="orange" @click="showModal = true")
    v-icon add
  v-text-field(clearable prepend-inner-icon="mdi-magnify" label="Search" v-model="search")
  v-list
    v-list-item(v-for="model in filteredModels" :key="model['.key']")
      v-list-item-content
        v-list-item-title {{model.make + ' ' + model.name}}
      v-list-item-action
        v-btn(text color="orange" @click="navToModel(model)") View Specs
  v-dialog(v-model="showModal" max-width="500px")
    v-card
      v-card-actions
        v-spacer
        v-btn(color="orange" text @click="showModal=false") Close
      v-card-text
        form
          v-autocomplete(v-model="newModel.make" :items="makes" item-text="name" auto-select-first item-value="name" label="Make")
          v-text-field(v-model="newModel.name" label="Name" type="text")
          v-checkbox(label="Has Pictures?" v-model="newModel.pictures")
          v-textarea(name="input-7-1" label="Description" v-model="newModel.description")
      v-card-actions
        v-btn(color="primary" @click="addModel()") Add Unit
</template>
<script>
import modal from '@/components/modal'
import { mapGetters } from 'vuex'

function initialUnit () {
  return {
    name: '',
    make: '',
    pictures: false,
    description: ''
  }
}

export default {
  name: 'app',
  data () {
    return {
      showModal: false,
      selected: '',
      search: '',
      newModel: initialUnit()
    }
  },
  methods: {
    navToModel (model) {
      this.$router.push({ path: `/models/${model.id}` })
    },
    resetModel () {
      this.showModal = false
      this.newModel = initialUnit()
    },
    addModel () {
      this.search = this.newModel.name
      this.$store.dispatch('addModel', this.newModel)
      this.resetModel()
    }
  },
  computed: {
    ...mapGetters([
      'models',
      'makes'
    ]),
    filteredModels () {
      var that = this
      var data = this.models
      return data.filter(model => {
        var searchRegex = new RegExp(that.search, 'i')
        return searchRegex.test(model.name)
      })
    }
  },
  components: {
    modal
  }
}
</script>
<style lang="stylus" scoped>

</style>
